import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, graphql } from "gatsby";
import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

// @ts-expect-error false positive image import failure
import imgCase1 from "../../assets/image/case-studies/southend-small.jpg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../../assets/image/case-studies/kingdom-small.jpg";
// @ts-expect-error false positive image import failure
import imgCase3 from "../../assets/image/case-studies/west-suffolk-small.jpg";
// @ts-expect-error false positive image import failure
import imgBrand1 from "../../assets/image/case-studies/southend-logo.png";
// @ts-expect-error false positive image import failure
import imgBrand2 from "../../assets/image/case-studies/kingdom-logo.png";
// @ts-expect-error false positive image import failure
import imgBrand3 from "../../assets/image/case-studies/west-suffolk-logo.png";
// @ts-expect-error false positive image import failure
import imgCase4 from "../../assets/image/case-studies/hemel-small.jpg";
// @ts-expect-error false positive image import failure
import imgBrand4 from "../../assets/image/case-studies/dacorum-borough-council-logo.png";

const CaseList = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-start">
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCard
              img={imgCase1}
              imgBrand={imgBrand1}
              title="Smart Radio in Southend"
              subTitle="Smart Radio"
              to="/southend-case-study"
            >
              The ‘Intelligence Hub’ will constitute a significant step forward
              - transforming how services are delivered and offering potential
              for revenue generation and trading.
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCard
              img={imgCase2}
              imgBrand={imgBrand2}
              title="Kingdom Shopping Centre"
              subTitle="Internal Radios"
              to="/kingdom-shopping-centre-case-study"
            >
              Kingdom Shopping Centre in Glenrothes, fife Gets a Two For One
              system - DMR digital radio and an rfid powered tour guarding
              solution in one.
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCard
              img={imgCase3}
              imgBrand={imgBrand3}
              title="West Suffolk Council"
              subTitle="Town Link"
              to="/west-suffolk-case-study"
            >
              ShopSafe delivers a fully integrated radio network across all the
              major towns – Bury St Edmunds, Newmarket, Haverhill, Stowmarket
              and Mildenhall.
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCard
              img={imgCase4}
              imgBrand={imgBrand4}
              title="Dacorum Borough Council"
              subTitle="Town Link"
              to="/dacorum-council-case-study"
            >
              ShopSafe’s solution delivered a fully featured digital radio
              system with capacity to deliver both ‘local’ services tailored for
              a single town or ‘wide-area’ groups operating throughout the
              borough.
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-0">
            <PostCard
              img={imgCase4}
              imgBrand={imgBrand4}
              title="test"
              subTitle="Town Link"
              to="/case-study/test-case-study"
            >
              ShopSafe’s solution delivered a fully featured digital radio
              system with capacity to deliver both ‘local’ services tailored for
              a single town or ‘wide-area’ groups operating throughout the
              borough.
            </PostCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseList;
