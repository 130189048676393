import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Title, Text, Button, List } from "../../components/Core";

// @ts-expect-error false positive image import failure
import imgCase1 from "../../assets/image/jpeg/case-details--details.jpg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../../assets/image/jpeg/case-details-half-single.jpg";
// @ts-expect-error false positive image import failure
import imgCase3 from "../../assets/image/jpeg/case-details-half-single-2.jpg";

const ImgContainer = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
`;

const CaseDetails = () => (
  <>
    <Section className="position-relative" borderBottom="1px solid #eae9f2;">
      <Container>
        <Row>
          <Col lg="12" xl="10" className="offset-xl-1">
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                Creating a ‘Smart City’
              </Title>
              <Text variant="small">
                With a clear vision within their ‘Digital Strategy 2017/2020’ Southend Council laid the foundation to utilise technology to develop Southend as a Smart City. Key to that plan was to build an Intelligence Hub, to be both the ‘eyes and ears’ of the Borough and the ‘nerve centre’ through which service responses, manual or automated, can be triggered on a 24/7 basis building on the existing CCTV facility.
              </Text>
            </Box>
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                About the Client
              </Title>
              <Text variant="small">
                Southend-on-Sea lies on the north side of the Thames Estuary 40 miles to the east of central London. With a population of 180,000, Southend is the seventh most densely populated area in the UK outside of the London Boroughs. The Council had drawn up a wide-ranging digital strategy targeting a number of areas as part of a wider aspiration to obtain ‘Smart City’ accreditation.
                <br /><br/>
                “Innovation is vital to the UK’s success as a global nation, and we are delighted to be playing a key role in this. We want to further enable our citizens and businesses to grow and evolve” said Nick Corrigan, head of digital futures at Southend-on-Sea Borough Council.
              </Text>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mb-lg-0 mb-30">
            <Box>
              <img src={imgCase2} alt="" className="img-fluid" />
            </Box>
          </Col>
          <Col lg="6">
            <Box>
              <img src={imgCase3} alt="" className="img-fluid" />
            </Box>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="12" xl="10" className="offset-xl-1 pt-4">
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                The Challenges
              </Title>
              <Text variant="small" mb={3}>
                The Council were clear from the outset that “a local Authority alone could not deliver this strategy, indeed that partnerships and joint ventures with solution providers were vital” and that “challenges in respect of annual budget cycles and departmental silos which can make long term investment difficult across areas of multiple responsibility must be overcome”.
                <br /><br />
                The Digital Strategy required the Council to adopt a ‘digital-by-default’ ethos in respect of its interactions with public, its internal processes and, wherever practicable service delivery. In addition to the enablement of infrastructure, the Council needed to create a locality to which monitoring of ‘people’ and ‘place‘ related events could be reported - the Southend ‘Intelligence Hub’.
                <br /><br />
                “The creation of such a facility constitutes a significant step forward in the ability of the Council to transform the way in which services are delivered while, in longer term offering potential for revenue generation and trading.”
              </Text>
              <List>
                <li>
                  This role will work with stakeholders and other developers to
                  design and implement.
                </li>
                <li>
                  Create custom landing pages with Omega that converts more
                  visitors than any website. With lots of unique blocks, you can
                  easily build a page without any design or custom coding.
                </li>
                <li>
                  You might be surprised to know that not only do we run some of
                  the biggest websites in the world; we’re also growing really
                  fast! We have close to 600 staff and contractors worldwide,
                  adding more than 100 people to the business, which grows year
                  on year, since 2017.
                </li>
              </List>
            </Box>
            <Box pb={["40px", null, "65px"]}>
              <Title variant="card" mb="18px">
                Learnings
              </Title>
              <Text variant="small">
                A Senior Data Engineer provides technical and delivery
                leadership for a team of developers working on data integration
                and processing projects. This role will work with stakeholders
                and other developers to design and implement technical data
                solutions for the business in a way that balances quality, cost,
                time and maintainability.
              </Text>
            </Box>
            <Box>
              <Button>Check live website</Button>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CaseDetails;
