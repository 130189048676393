import React, { ReactNode, useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import { Link } from "gatsby";
import { Title, Box, Text, Span } from "../Core";
import { device } from "../../utils";
import { BoxProps } from "../Core/Box";

const Card = styled(Box)<BoxProps>`
  border-radius: 10px 10px;
  border: 1px solid #eae9f2;
  transition: 0.4s;
  height: 100%;
  overflow: hidden;

  &:hover {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  }
`;

const ImageContainerHorizontal = styled(Box)<BoxProps>`
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: 1/1 !important;

  @media ${device.md} {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
  @media ${device.lg} {
    min-width: 265px;
  }
  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`;

const BrandImage = styled(Box)<BoxProps>`
  position: absolute;
  bottom: 28px;
  left: 30px;
  border-radius: 8px;
  border: 1px solid #eae9f2;
  overflow: hidden;
  background-color: #e0e0e0;
`;

const CardText = styled(Box)<BoxProps>`
  padding: 30px;
`;

interface TitleStyledProps {
  theme: DefaultTheme;
}

const TitleStyled = styled(Title)<TitleStyledProps>`
  transition: 0.3s;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

interface SubHeaderProps {
  theme: DefaultTheme;
}

const SubHeader = styled(Text)<SubHeaderProps>`
  color: #f04037;
  font-weight: 700;
`;
interface ReadMoreProps {
  theme: DefaultTheme;
}

const ReadMore = styled(Span)<ReadMoreProps>`
  text-align: center;
  width: 100%;
`;
const CardImg = styled.div`
  background-color: #eceff1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media ${device.lg} {
    max-width: 350px;
    max-height: 280px;
  }

  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
    max-height: 350px;
  }
`;

const CardButtonRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eceff1;
  background-color: #ef5350;

  &:before {
    content: "";
    position: absolute;
    z-index: ${({ hover }) => (hover ? "0" : "-1")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: ${({ hover }) => (hover ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: 100% 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ${({ hover }) =>
      hover ? "cubic-bezier(0.52, 1.64, 0.37, 0.66)" : "ease-out;"};
    background: #e57373;
  }
`;

const ButtonText = styled(Text)`
  z-index: 1;
`;

interface ContentProps {
  horizontal?: boolean;
  img?: string;
  imgBrand?: string;
  preTitle?: unknown;
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  readMore?: unknown;
  buttonText?: string;
  [propName: string]: unknown;
}

const PostCardContent: React.FC<ContentProps> = ({
  horizontal = false,
  img,
  imgBrand,
  preTitle,
  title,
  subTitle,
  children,
  readMore,
  buttonText,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Card
      className={horizontal ? "d-flex flex-column flex-sm-row " : ""}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...rest}
    >
      {horizontal ? (
        <ImageContainerHorizontal>
          <img src={img} alt="" className="w-100 img-fluid" />
          {imgBrand && (
            <BrandImage>
              <img src={imgBrand} alt="" className="img-fluid" />
            </BrandImage>
          )}
        </ImageContainerHorizontal>
      ) : (
        <Box className="position-relative">
          <CardImg>
            <img src={img} alt="" style={{ height: 300 }} className="img-fluid" />
          </CardImg>
          {imgBrand && (
            <BrandImage>
              <img src={imgBrand} alt="" className="img-fluid" />
            </BrandImage>
          )}
        </Box>
      )}
      {preTitle ||
        (subTitle && (
          <CardText>
            {preTitle && (
              <Text fontSize={2} lineHeight={1.75} mb="14px">
                Jan 14, 2020
              </Text>
            )}

            <TitleStyled variant="card" mb="0px">
              {title}
            </TitleStyled>
            {subTitle && (
              <SubHeader fontSize={2} lineHeight={1.75} mb="14px">
                {subTitle}
              </SubHeader>
            )}
            {!!children && (
              <Text fontSize={2} lineHeight={1.75} mb="16px">
                {children}
              </Text>
            )}
          </CardText>
        ))}
      {buttonText && (
        <CardButtonRow hover={hover}>
          <ButtonText
            color="light"
            fontSize="14px"
            fontWeight="600"
            lineHeight={1.75}
          >
            <span style={{ color: "#FFF" }}>{buttonText}</span>
          </ButtonText>
        </CardButtonRow>
      )}
    </Card>
  );
};

interface Props {
  horizontal?: boolean;
  img?: string;
  imgAlt: string;
  imgBrand?: string;
  preTitle?: unknown;
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  readMore?: unknown;
  to?: string;
  buttonText?: string;
  [propName: string]: unknown;
}

const PostCard = ({ to, ...rest }: Props) => {
  return to ? (
    <Link to={to}>
      <PostCardContent {...rest} />
    </Link>
  ) : (
    <PostCardContent {...rest} />
  );
};

export default PostCard;
